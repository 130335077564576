// 工具类方法

export default {
  // 生成1-3之间的随机数字
  randomNum(m, n) {
    return Math.round(Math.random() * (n - 1)) + m;
  },
  // 生成随机中文
  randomChinese() {
    let _rsl = "";
    let _randomUniCode = Math.floor(
      Math.random() * (40870 - 19968) + 19968
    ).toString(16);
    eval("_rsl=" + '"\\u' + _randomUniCode + '"');
    return _rsl;
  },
  // 生成指定长度的随机中文字符串
  randomChineseString(len) {
    let str = "";
    for (let i = 0; i < len; i++) {
      str += this.randomChinese();
    }
    // console.log("str", str);
    return str;
  },
  // 关键词红色高亮
  highlight(text, keyword, font_size) {
    if (text.indexOf(keyword) != -1) {
      text = text.replace(
        eval(`/${keyword}/g`),
        `<span style="color:red;font-size:${font_size};">${keyword}</span>`
      );
      // console.log("text", text)
      return text;
    } else {
      return text;
    }
  },
  // 获取某元素在数组中的下标
  getArrIndex(arr, val) {
    for(let i=0;i<arr.length;i++) {
      if (arr[i]==val) return i
    }
    return -1
  },
  // 获取某元素在对象数组中的下标(根据某属性)
  getObjArrIndex(arr, attr, val) {
    for(let i=0;i<arr.length;i++) {
      if (arr[i][attr]==val) return i
    }
    return -1
  },
  // 删除数组的某元素
  arrRemove(arr, val) {
    let index = this.getArrIndex(arr, val)
    if (index > -1) {
      arr.splice(index, 1)
    }
  },
  // 删除对象数组的某元素
  objArrRemove(arr, attr, val) {
    let index = this.getObjArrIndex(arr, attr, val)
    if (index > -1) {
      arr.splice(index, 1)
    }
  },
  // 动态添加css
  addCSS(style_text) {
    let s = document.createElement('style')
    s.innerText = style_text
    document.head.appendChild(s)
  },
  // 滚动回顶部
  scrollToTop(id) {
    document.getElementById(
      id
    ).scrollTop = 0;
  },
  // 滚动回顶部（根据class）
  scrollToTopByClass(className) {
    let div_crew = document.getElementsByClassName(className)
    for (let i=0;i<div_crew.length;i++) {
      div_crew[i].scrollTop = 0
    }
    console.log("===已完成===")
  },
  // 修改es的highlight默认样式
  esHL(text) {
    if (!text) return // 一开始text为空，会报错
    // console.log("text", text)
    text = text.replace(/<em>/g, '<font style="color:red;">')
    text = text.replace(/<\/em>/g, '</font>')
    // console.log("text", text)
    return text
  },
};
