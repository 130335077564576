<template>
  <div class="structureTree">
    <div class="treeContainer">
      <div class="treeChart" ref="treeChart"></div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "../utils/eventBus.js";
export default {
  name: "StructureTree",
  props: {
    apiData: Array,
  },
  data() {
    return {
      treeChart: "",
      treeData: {
        name: "牵引车",
        children: [
          {
            name: "第一弹簧",
            children: [
              {
                name: "cluster",
                children: [
                  { name: "AgglomerativeCluster", value: "" },
                  { name: "CommunityStructure", value: "" },
                  { name: "HierarchicalCluster", value: "" },
                  { name: "MergeEdge", value: "" },
                ],
              },
              {
                name: "xxx",
                children: [
                  { name: "BetweennessCentrality", value: "" },
                  { name: "LinkDistance", value: "" },
                  { name: "MaxFlowMinCut", value: "" },
                  { name: "ShortestPaths", value: "" },
                  { name: "SpanningTree", value: "" },
                ],
              },
              {
                name: "",
                children: [{ name: "AspectRatioBanker", value: "" }],
              },
            ],
          },
          {
            name: "主车体",
            children: [
              { name: "Easing", value: "" },
              { name: "FunctionSequence", value: "" },
              {
                name: "interpolate",
                children: [
                  { name: "ArrayInterpolator", value: "" },
                  { name: "ColorInterpolator", value: "" },
                  { name: "DateInterpolator", value: "" },
                  { name: "Interpolator", value: "" },
                  { name: "MatrixInterpolator", value: "" },
                  { name: "NumberInterpolator", value: "" },
                  { name: "ObjectInterpolator", value: "" },
                  { name: "PointInterpolator", value: "" },
                  { name: "RectangleInterpolator", value: "" },
                ],
              },
              { name: "ISchedulable", value: "" },
              { name: "Parallel", value: "" },
              { name: "Pause", value: "" },
              { name: "Scheduler", value: "" },
              { name: "Sequence", value: "" },
              { name: "Transition", value: "" },
              { name: "Transitioner", value: "" },
              { name: "TransitionEvent", value: "" },
              { name: "Tween", value: "" },
            ],
          },
        ],
      },
    };
  },
  watch: {
    apiData: {
      async handler(newVal, oldVal) {
        console.log("apiData", newVal);
      },
      deep: true,
    },
  },
  methods: {
    // 监听图谱数据
    listenStructureData() {
      EventBus.$on("structureData", (data) => {
        console.log("structureData", data);
        this.graphData = [];
        this.graphLink = [];
        data.structureNode.forEach((i) => {
          this.graphData.push({
            id: i.node_id,
            name: i.node_name,
            category: i.level,
          });
        });
        data.structureTree.forEach((i) => {
          this.graphLink.push({
            source: i.from_id,
            target: i.to_id,
            value: i.edge,
          });
        });
        this.setOriginGraphChart();
        this.setGraphChart();
      });
    },
    // 对象数组根据属性去重
    unique(arr, attr) {
      const res = new Map();
      return arr.filter((a) => !res.has(a[attr]) && res.set(a[attr], 1));
    },
    // echart初始化
    setOriginTreeChart() {
      echarts.init(this.$refs.treeChart).dispose();
      this.treeChart = echarts.init(this.$refs.treeChart);
    },
    // 渲染echart
    setTreeChart() {
      this.treeData.children.forEach(function (datum, index) {
        index % 2 === 0 && (datum.collapsed = true);
      });
      let option = {
        tooltip: {
          trigger: "item",
          triggerOn: "mousemove",
        },
        series: [
          {
            type: "tree",
            data: [this.treeData],
            top: "1%",
            left: "7%",
            bottom: "1%",
            right: "20%",
            symbolSize: 15,
            label: {
              position: "left",
              verticalAlign: "middle",
              align: "right",
              fontSize: 14,
            },
            leaves: {
              label: {
                position: "right",
                verticalAlign: "middle",
                align: "left",
              },
            },
            emphasis: {
              focus: "descendant",
            },
            expandAndCollapse: true,
            animationDuration: 550,
            animationDurationUpdate: 750,
          },
        ],
      };
      this.treeChart.setOption(option)
    },
  },
  mounted() {
    this.setOriginTreeChart();
    this.setTreeChart();
  },
  // beforeDestroy() {
  //   EventBus.$off("structureData")
  // }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.structureTree {
  .treeContainer {
    width: 96%;
    margin-left: 2%;
    height: 70vh;
  }
  .treeChart {
    width: 100%;
    // height: 30vw;
    height: 100%;
    margin-top: 5px;
  }
}
</style>
