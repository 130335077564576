<template>
  <div class="patentCardListWrapper" id="patentCardListWrapper">
    <div class="patentCardList">
      <div
        v-for="p in apiData"
        :key="p.num"
        style="width: 98%; margin-left: 1%"
      >
        <PatentCard
          :patent="p"
          :showModal="showModal"
          :showRadio="showRadio"
          :isFavorites="isFavorites"
          @cancelStar="handleCancelStar"
          :hasKeyword="hasKeyword"
          :markable="markable"
          :company_id="company_id"
          :searchValue="searchValue"
          :searchType="searchType"
        ></PatentCard>
      </div>
    </div>
    <!-- <div class="showMore" @click="showMore">
        <p>{{ showMoreText }}</p>
      </div> -->
    <div
      style="
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 15px 0 15px 0;
      "
    >
      <el-pagination
        background
        layout="total, prev, pager, next, jumper"
        :current-page.sync="page"
        :total="totalPatent"
        :page-size="page_size"
        :page-count="5"
        @current-change="handleChangePage"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import utils from "@/utils/utils.js";
import PatentCard from "@/components/PatentCard.vue";

export default {
  name: "PatentCardList",
  props: {
    apiData: Array,
    showModal: Boolean,
    showRadio: Boolean,
    isFavorites: Boolean,
    page: Number,
    page_size: Number,
    total: Number,
    hasKeyword: String,
    limit_page: Number,
    markable: Boolean, // 是否可标注
    company_id: Number, // 该新闻列表对应的公司id（仅在产业搜索详情有用）
    searchValue: String, // 搜索内容（仅在产业搜索详情有用）
    searchType: Number,
  },
  watch: {
    page: {
      handler(newVal) {
        utils.scrollToTopByClass("patentCardListWrapper"); // 滚动回顶部
        console.log("滚动回顶部");
      },
      deep: true
    },
    apiData: {
      handler(newVal) {
        if (this.limit_page) {
          this.totalPatent =
            this.total > this.page_size * this.limit_page
              ? this.page_size * this.limit_page
              : this.total;
        } else {
          this.totalPatent = this.total;
        }
      },
      deep: true,
    },
  },
  components: {
    PatentCard,
  },
  data() {
    return {
      showMoreText: "加载更多",
      totalPatent: 0,
    };
  },
  mounted() {
    if (this.limit_page) {
      this.totalPatent =
        this.total > this.page_size * this.limit_page
          ? this.page_size * this.limit_page
          : this.total;
    } else {
      this.totalPatent = this.total;
    }
  },
  methods: {
    handleChangePage(val) {
      this.$emit("changePage", val);
    },
    showMore() {
      this.$emit("showMore", 1);
    },
    handleCancelStar(val) {
      // utils.objArrRemove(this.apiData, "id", val)
      this.$emit("cancelStar", 1);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.patentCardListWrapper {
  height: 100%;
  overflow-y: scroll;
  .patentCardList {
    // max-height: 70vh;
    // overflow: scroll;
    margin-top: 10px;
    padding-top: 10px;
  }
  .showMore {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #333333;
    font-size: 14px;
    p {
      cursor: pointer;
      background-color: rgba(244, 244, 244, 1);
      padding: 5px 40px 5px 40px;
      &:hover {
        background-color: rgb(228, 226, 226);
      }
    }
  }
}
</style>
