<template>
  <div class="echartRiver">
    <div id="riverEcharts" v-if="riverLegendList.length > 0"></div>
    <div v-if="riverLegendList.length == 0 && riverLoading == false">
      <p style="text-align: center">抱歉，该企业暂无数据</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "EchartRiver",
  props: {
    riverDataProp: Array,
    riverLoading: Boolean
  },
  data() {
    return {
      myChart: "",
      riverLegendList: [],
      riverData: [],
    };
  },
  watch: {
    riverDataProp: {
      handler(newValue) {
        this.formatDataAndSetEchart(newValue);
      },
    },
  },
  methods: {
    formatDataAndSetEchart(newValue) {
      const data = newValue;
      let legend = [];
      let river_data = [];
      console.log(data);
      // 河流图信息加工处理
      for (let i = 0; i < data.length; i++) {
        let cur_year_count = data[i]["year_count"];
        let years = Object.keys(cur_year_count);
        for (let j = 0; j < years.length; j++) {
          let legend_name = data[i]["ipc_num"] + data[i]["info"];
          // let name = data[i]["ipc_num"];
          // legend添加
          if (!legend.includes(legend_name)) {
            legend.push(legend_name);
          }
          let tmp_data = [years[j], cur_year_count[years[j]], legend_name];
          river_data.push(tmp_data);
        }
      }
      this.riverLegendList = legend;
      this.riverData = river_data;
      this.$nextTick(() => {
        this.setRiverChart();
      });
    },

    async setRiverChart() {
      var chartDom = document.getElementById("riverEcharts");
      this.myChart = echarts.init(chartDom);
      var option;
      option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "line",
            lineStyle: {
              color: "rgba(0,0,0,0.2)",
              width: 1,
              type: "solid",
            },
          },
          enterable: true, //滚动条
          extraCssText: "max-width:60%;max-height:83%; overflow: auto; ", //滚动条
          position: function (point, params, dom, rect, size) {
            //其中point为当前鼠标的位置，
            //size中有两个属性：viewSize和contentSize，分别为外层div和tooltip提示框的大小
            // 鼠标坐标和提示框位置的参考坐标系是：以外层div的左上角那一点为原点，x轴向右，y轴向下
            // 提示框位置
            var x = 0; // x坐标位置
            var y = 0; // y坐标位置
            // 当前鼠标位置
            var pointX = point[0];
            var pointY = point[1];
            // 提示框大小
            var boxWidth = size.contentSize[0];
            var boxHeight = size.contentSize[1];
            // boxWidth > pointX 说明鼠标左边放不下提示框
            if (boxWidth > pointX) {
              x = 5;
            } else {
              // 左边放的下
              x = pointX - boxWidth;
            }
            // boxHeight > pointY 说明鼠标上边放不下提示框
            if (boxHeight > pointY) {
              y = 5;
            } else {
              // 上边放得下
              y = pointY - boxHeight;
            }
            return [x, y];
          },
          // formatter: '{a1}<br />{b1}<br/>{c1}<br/>{d1}'
          formatter: (params) => {
            let result = params[0].data[0].toString() + "年<br/>"
            params.forEach(p => {
              if (p.data[1] > 0) {
                result += `${p.data[2]} : ${p.data[1]}<br/>`
              }
            })
            return result
          }
        },
        legend: {
          show: true,
          type: "scroll",
          data: this.riverLegendList,
        },
        singleAxis: {
          max: "dataMax",
          top: 50,
          bottom: 50,
          axisTick: {},
          axisLabel: {},
          type: "time",
          axisPointer: {
            animation: true,
            label: {
              show: true,
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
              opacity: 0.2,
            },
          },
        },
        series: [
          {
            type: "themeRiver",
            emphasis: {
              itemStyle: {
                shadowBlur: 20,
                shadowColor: "rgba(0, 0, 0, 0.8)",
              },
            },
            data: this.riverData,
            label: {
              show: false,
            },
          },
        ],
      };
      option && this.myChart.setOption(option);
    },
  },
  mounted() {
    // this.formatDataAndSetEchart(this.riverDataProp)
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
#riverEcharts {
  width: 100%;
  height: 680px;
}
</style>
