<template>
  <div class="star">
    <el-tooltip content="订阅" placement="top" effect="light">
      <div class="starItem" @click.stop.prevent="handleStar(apiData)">
        <img class="starItem-img" :src="apiData.star == true? require('@/assets/images/star.png') : require('@/assets/images/star_empty.png') ">
      </div>
    </el-tooltip>
  </div>
</template>

<script>
import api from "@/api/api";
import cookie from "@/utils/common.js"

export default {
  name: 'Star',
  props: {
    apiData: Object,
    type: String,
    isFavorites: Boolean,
    isSearch: Boolean // 是否是产业搜索或者产业图谱（因为产业搜索的公司列表是company_id，需要特殊处理）
  },
  methods: {
    // 响应点击收藏
    handleStar(i) {
      console.log("响应点击收藏", i)
      i.star = !i.star
      let params = {
        user: cookie.getCookie("account"),
        data_id: this.isSearch ? i.company_id : i.id,
        star_type: this.type
      }
      if (i.star == true) {
        api.addUserStar(params).then(res => {
          console.log("res", res)
          if (res.data == 1) {
            this.$message.success("添加订阅成功！")
          } else {
            this.$message.error("添加订阅失败！")
          }
        })
      } else {
        api.cancelUserStar(params).then(res => {
          console.log("res", res)
          if (res.status == 200) {
            if (this.isFavorites) {
              this.$emit("cancelStar", i.id)
            }
            this.$message.success("取消订阅成功！")
          } else {
            this.$message.error("取消订阅失败！")
          }
        })
      }
    },
  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.star {
  .starItem {
    margin-left: 5px;
    cursor: pointer;
    &-img {
      width: 15px !important;
      height: 15px !important;
    }
  }
}

</style>
