<template>
  <div class="structureLink">
    <div class="graphContainer">
      <!-- <div class="empty" v-show="structureData.structureNode.length == 0">
        <img src="@/assets/images/empty.png">
        <p>暂无数据</p>
      </div> -->
      <div class="graphChart" ref="graphChart" ></div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "../utils/eventBus.js";
export default {
  name: "StructureLink",
  props: {
    structureData: Object,
  },
  data() {
    return {
      graphChart: "",
      graphData: [],
      graphLink: [],
      structureNode: [],
      structureLink: [],
    };
  },
  watch: {
    structureData: {
      handler(newVal, oldVal) {
        console.log("apiData", newVal)
        this.setOriginGraphChart()
        this.graphData = []
        this.graphLink = []
        let name_id_map = {}
        let pos_id_map = {}
        newVal.structureNode.forEach(i => {
          name_id_map[i.node_id] = i.node_name
          pos_id_map[i.node_id] = i.pos
          this.graphData.push({
            node_id: i.node_id,
            name: i.node_name,
            // category: i.pos == 1?  i.level + 6 : i.level
            category: i.level,
            itemStyle: i.pos == 1 ? {
              normal: {
                borderColor: "#337AB7",
                borderWidth: 5,
              }
            } : {}
          })
        });
        newVal.structureLink.forEach(i => {
          this.graphLink.push({
            source: name_id_map[i.from_id],
            target: name_id_map[i.to_id],
            value: i.edge_name,
            lineStyle: {
              normal: {
                color: (pos_id_map[i.from_id] & pos_id_map[i.to_id]) == 1 ? '#337ab7' : '#aaa',
                width: (pos_id_map[i.from_id] & pos_id_map[i.to_id]) == 1 ? 2 : 1,
              }
            }
          })
        })
        this.graphData = this.unique(this.graphData, "name")
        this.setGraphChart()
      },
      deep: true
    },
  },
  methods: {
    // 对象数组根据属性去重
    unique(arr, attr) {
      const res = new Map();
      return arr.filter((a) => !res.has(a[attr]) && res.set(a[attr], 1));
    },
    // echart初始化
    setOriginGraphChart() {
      echarts.init(this.$refs.graphChart).dispose();
      this.graphChart = echarts.init(this.$refs.graphChart);
      this.graphChart.on("click", function(params) {
        if (params.data.category == 3 || params.data.category == 4) {
          console.log("params", params.data)
          EventBus.$emit("searchIndustryNews", params.data.name)
        }
      })
    },
    // 渲染echart
    setGraphChart() {
      let option = {
        legend: {
          x: "left",
          data: [
            "一级",
            "二级",
            "三级",
            "四级",
            "五级",
            "六级",
          ],
          show: this.graphData.length!=0,
          textStyle: {
            fontSize: 13, //字体大小
            color: "#333333", //字体颜色
          },
        },
        animation: false,
        animationDurationUpdate: 1500,
        animationEasingUpdate: 'quinticInOut',
        series: [
          {
            type: "graph",
            layout: "force",
            force: {
              layoutAnimation: false,
              repulsion: 1200,
            },
            // focusNodeAdjacency: false,
            //symbolSize: 65,
            // force: {
            //   layoutAnimation: "false",
            //   initLayout: "circular"
            // },
            roam: true,
            //categories:this.ca,
            lineStyle: {
              // 关系边的公用线条样式。其中 lineStyle.color 支持设置为'source'或者'target'特殊值，此时边会自动取源节点或目标节点的颜色作为自己的颜色。
              normal: {
                color: "#aaa", // 线的颜色[ default: '#aaa' ]
                width: 10, // 线宽[ default: 1 ]
                type: "solid", // 线的类型[ default: solid ]   'dashed'    'dotted'
                opacity: 1, // 图形透明度。支持从 0 到 1 的数字，为 0 时不绘制该图形。[ default: 0.5 ]
                curveness: 0.5, // 边的曲度，支持从 0 到 1 的值，值越大曲度越大。[ default: 0 ]
              },
            },
            categories: [
              {
                name: "一级",
                itemStyle: {
                  label: {
                    show: true,
                  },
                  normal: {
                    color: "orange",
                  },
                  nodeStyle: {
                    brushType: "both",
                    borderWidth: 1,
                  },
                },
                symbolSize: 55,
              },
              {
                name: "二级",
                // symbol: "roundRect",
                itemStyle: {
                  normal: {
                    color: "#1E90FF",
                  },
                  nodeStyle: {
                    brushType: "both",
                    borderWidth: 1,
                  },
                },
                symbolSize: 40,
              },
              {
                name: "三级",
                itemStyle: {
                  normal: {
                    color: "#00CED1",
                  },
                  nodeStyle: {
                    brushType: "both",

                    borderWidth: 1,
                  },
                },
                symbolSize: 35,
              },
              {
                name: "四级",
                // symbol: "roundRect",
                itemStyle: {
                  normal: {
                    color: "#4B0082",
                  },
                  nodeStyle: {
                    brushType: "both",
                    borderWidth: 1,
                  },
                },
                symbolSize: 25,
              },
              {
                name: "五级",
                itemStyle: {
                  normal: {
                    color: "#EA7CCC",
                  },
                  nodeStyle: {
                    brushType: "both",
                    borderWidth: 1,
                  },
                },
                symbolSize: 20,
              },
              {
                name: "六级",
                itemStyle: {
                  normal: {
                    color: "#FC8452",
                  },
                  nodeStyle: {
                    brushType: "both",
                    borderWidth: 1,
                  },
                },
                symbolSize: 15,
              },
            ],
            label: {
              normal: {
                show: true,
                //position:'top',
                textStyle: {
                  fontSize: 12,
                  // color: "black"
                },
              },
            },
            edgeSymbol: ["none", "arrow"],
            edgeSymbolSize: [10, 7],
            edgeLabel: {
              normal: {
                show: true,
                textStyle: {
                  fontSize: 13,
                },
                formatter: "{c}",
              },
            },
            // data: graph_info["欣旺达"].data.nodes,
            data: this.graphData,
            // links: graph_info["欣旺达"].data.edge,
            links: this.graphLink,
            lineStyle: {
              normal: {
                opacity: 0.9,
                width: 1,
                curveness: 0.3,
              },
            },
          },
        ],
      };
      this.graphChart.setOption(option);
    },
  },
  mounted() {
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.structureLink {
  .graphContainer {
    width: 96%;
    margin-left: 2%;
    height: 450px;
  }
  .graphChart {
    width: 100%;
    // height: 30vw;
    height: 100%;
    margin-top: 5px;
  }
  .empty {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    img {
      width: 50%;
    }
  }
}
</style>
