<template>
  <div class="industryPerson">
    <div class="industryPerson-left" v-loading="loadLabel">
      <div class="industryPerson-left-top"></div>
      <div class="industryPerson-left-buttom" >
        <div  class="list" v-for="item in industrialTalentsData" :key="item.id" @click="isSelected(item)">
            <IndustrialTalents @clickRiver="handleClickRiver" :talentsData="item" :style="colorSelect == item.id ?'background-color:  #DCF3FD;' : 'background-color:  #fff;'"
            class="industry"></IndustrialTalents>
        </div>
        <div
          style="
            width: 100%;
            display: flex;
            justify-content: center;
            margin: 15px 0 15px 0;
          "
          >
          <el-pagination
            background
            layout="prev, pager, next"
            :current-page.sync="page_params.page"
            :total="page_params.pageCount"
            :page-size="page_params.pageSize"
            :page-count="7"
            @current-change="handlePageChange"
            hide-on-single-page
          >
          </el-pagination>
        </div>
      </div>
      
    </div>
    <div class="industryPerson-right">
      <div class="industryPerson-right-top">
        <!-- <Icon type="md-thumbs-up" /> -->
        <div class="industryPerson-right-top-flexBox">
          <img src="@/assets/images/collaborator.png" alt="">
          <span>相关合作者</span>
        </div>
         
      </div>
      <div class="industryPerson-right-buttom">
        <div class="collaboratorList" v-for="i in collaborator" :key="i.id">
          <el-card>
            <div class="collaboratorName">
              <img src="@/assets/images/businessCard.png" alt="">
              <span>{{ i.name }}</span> 
              </div>
            <div class="collaboratorCompany">
              <p v-for="(c,index) in i.company_name_list" :key="index">
                {{ c }}
              </p>
            </div>
          </el-card>
        </div>
      </div>
    </div>
    <el-dialog title="专利河流图" :visible.sync="showRiver" width="50%">
      <EchartRiver :riverDataProp="riverData" v-loading="riverLoading"></EchartRiver>
  </el-dialog>
  </div>
</template>
<script>
import api from "@/api/api";
import cookie from "@/utils/common.js";
import utils from "@/utils/utils.js";
import { EventBus } from "../utils/eventBus.js";
import IndustrialTalents from "../components/IndustrialTalents.vue"
import EchartRiver from "@/components/EchartRiver.vue"

export default {
  components: {
    IndustrialTalents,
    EchartRiver
  },
  data() {
    return {
      showRiver: false,
      loadLabel:false,
      colorSelect:null,
      collaborator:[],
      industrialTalentsData:[],
      riverLoading: false,
      clickData:[],
      riverData: [],
      page_params:{
        'page':0,
        'pageSize':10,
        'pageCount':0
      },
    };
  },
  methods: {
    handleClickRiver(val) {
      let params = {
          inventor_id: val.id,
          count: 1000,
          years: 13
      }
      this.showRiver = true
      this.riverLoading = true
      api.getInventorRiver(params).then(res => {
        // this.showRiver = true
        let river_tmp = JSON.parse(JSON.stringify(res.data))
        this.riverData = {}
        this.$nextTick(() => {
            this.riverData = river_tmp
            this.riverLoading = false
            // this.showRiver = true
        })
          
      })
    },
    listenTreeClick() {
      EventBus.$on("tree_click", (data) => {
        this.collaborator = []
        this.loadLabel = true
        this.clickData = data
        this.page_params.page = 1
        let params = {
          'node_id':data.node_id,
          'page':this.page_params.page,
          'page_size':this.page_params.pageSize
        }
        api.getIndustryTalents(params).then((res) =>{
          this.industrialTalentsData = res.data;
          this.page_params.pageCount = res.total;
          this.loadLabel = false
        })

      });
    },
    isSelected(item){
      this.colorSelect = item.id
      
  
      let params = {
        'inventor_id': item.id,
      }
      //api
      api.getCollaborator(params).then((res) => {
        this.collaborator = res.data
      })
    },
    handlePageChange(val){
      this.loadLabel = true
      this.page_params.page = val
      let params = {
          'node_id':this.clickData.node_id,
          'page':this.page_params.page,
          'page_size':this.page_params.pageSize
        }
      api.getIndustryTalents(params).then((res) =>{
          this.industrialTalentsData = res.data;
          this.page_params.pageCount = res.total;
          this.loadLabel = false
        })
    }
  },
  beforeDestroy() {},
  // 监听产业链中的产业树点击
  mounted() {
    this.listenTreeClick();
  },
  beforeDestroy() {
    EventBus.$off("tree_click");
  },
};
</script>

<style lang="scss">
.industryPerson {
  display: flex;
  .industryPerson-left{
    flex: 5;
    // background-color: green;
    .industryPerson-left-top{
      height: 50px;
      border:1px solid #DCDFE6;
      border-top: 0px;
      border-right: 0px;
    }
    .industryPerson-left-buttom{
      height: calc(100vh - 200px);
      overflow-y: scroll;
      scrollbar-color: #999 transparent;
      scrollbar-width: thin;
      border-left: 1px solid #DCDFE6;
      .list{
        // background-color: #;
        position: relative;
        .industry:hover{
          background-color: #ECF8FE !important;
          cursor: pointer;
        }
      }
    }
  }
  .industryPerson-right{
    flex: 2;
    // background-color: red;
    .industryPerson-right-top{
      height: 50px;
      font-size: 16px;
      color: #5394BD;
      text-align: center;
      border:1px solid #DCDFE6;
      border-top:0px;
      display: flex;
      align-items: center;
      justify-content: center;

      .industryPerson-right-top-flexBox{
        display: flex;
        justify-items: center;
        align-items: center;
        img{
        height: 30px;
        width: 30px;
      
        }
        span{
          line-height: 50px;
          margin-left: 5px;
        }
      }
      
    }
    .industryPerson-right-buttom{
      height: calc(100vh - 200px);
      overflow-y: scroll;
      scrollbar-color: #999 transparent;
      scrollbar-width: thin;
      border-right: 1px solid #DCDFE6;
      border-left: 1px solid #DCDFE6;
      // border-bottom: 1px solid #DCDFE6;
      .collaboratorList{
        // width: 100%;
        // background-color: green;
        margin-left: 10px;
        margin-top: 10px;
        margin-right: 5px;
        .el-card__body{
          padding: 5px;
          .collaboratorName{
            height: 40px;
            // background-color: green;
            font-size: 18px;
            line-height: 40px;
            font-weight: 900;
            img{
              width: 40px;
              height: 40px;
              float: left;
            }
            span{
              line-height: 40px;
              float: left;
              margin-left: 10px;
            }
          }
          .collaboratorCompany{
            p{
              font-size: 13px;
              margin-left: 50px;
            }
          }
        }
      }

    }
  }
}
</style>
