<template>
  <div class="patentDetailTotal">
    <el-dialog
      v-drag
      title="专利详情"
      :visible.sync="visible"
      width="80vw"
      destroy-on-close
      :modal="showModal"
      append-to-body
    >
      <div class="patentDetail" style="display: flex; width: 100%">
        <div class="patentDetail-side" style="width: 200px; overflow: hidden">
          <el-menu
            default-active="1"
            class="el-menu-vertical-demo"
            default-openeds="4"
          >
            <el-menu-item
              v-for="s in sideList"
              :key="s.name"
              :index="s.index"
              @click="jumpToWhere(s.ref)"
            >
              <span slot="title">{{ s.name }}</span>
            </el-menu-item>
            <el-submenu
              v-for="s in sideList2"
              :key="s.name"
              :index="s.index"
              @click.native="jumpToWhere(s.ref)"
            >
              <template slot="title">
                <span>{{ s.name }}</span>
              </template>
              <el-menu-item
                v-for="(m, index) in s.children"
                :key="m"
                @click.native.stop="jumpToWhere(`instructionSub${index}`)"
                :index="`4-${index}`"
              >
                <template slot="title">
                  {{ m }}
                </template>
              </el-menu-item>
            </el-submenu>
          </el-menu>
        </div>
        <div
          class="patentDetail-left"
          ref="patentContent"
          id="patentContent"
          style="
            width: 40%;
            height: 70vh;
            overflow-y: scroll;
            margin-left: 2%;
            padding-right: 2%;
            margin-right: 2%;
          "
        >
          <div
            class="patentDetail-box"
            ref="baseInfo"
            id="baseInfo"
            style="
              border-bottom: 1px solid rgb(229, 226, 226);
              margin-bottom: 10px;
            "
          >
            <p class="patentDetail-title" style="font-weight: bold">基本信息</p>
            <p>专利名：{{ patentDetailData.name }}</p>
            <p>公开号：{{ patentDetailData.num }}</p>
            <p>申请日：{{ patentDetailData.application_date }}</p>
            <p>公开日：{{ patentDetailData.public_date }}</p>
            <p>专利类型：{{ patentDetailData.patent_type }}</p>
            <p v-if="patentDetailData.status">
              专利状态：{{ patentDetailData.status }}
            </p>
            <p>专利权人：{{ patentDetailData.patenter }}</p>
            <p>当前专利权人：{{ patentDetailData.patenter_now }}</p>
            <p v-if="patentDetailData.inventor">
              发明人：{{ patentDetailData.inventor }}
            </p>
            <p v-else>设计人：{{ patentDetailData.designer }}</p>
            <p>IPC分类号：{{ patentDetailData.ipc }}</p>
            <p>CPC分类号：{{ patentDetailData.cpc }}</p>
          </div>
          <div
            class="patentDetail-box"
            ref="info"
            id="info"
            style="
              border-bottom: 1px solid rgb(229, 226, 226);
              margin-bottom: 10px;
            "
          >
            <p class="patentDetail-title" style="font-weight: bold">摘要</p>
            <p>{{ patentDetailData.info }}</p>
          </div>
          <div
            class="patentDetail-box"
            ref="claims"
            id="claims"
            style="
              border-bottom: 1px solid rgb(229, 226, 226);
              margin-bottom: 10px;
            "
            v-if="patentDetailData.hasClaims"
          >
            <p class="patentDetail-title" style="font-weight: bold">
              权力要求书
            </p>
            <!-- <p>{{patentDetailData.claims}}</p> -->
            <p v-for="i in patentDetailData.claims" :key="i.line">
              <span v-if="i.line">{{ i.line }}、{{ i.text }}</span>
              <span v-else v-html="i.text"></span>
            </p>
          </div>
          <div
            class="patentDetail-box"
            ref="instructions"
            id="instructions"
            style="
              border-bottom: 1px solid rgb(229, 226, 226);
              margin-bottom: 10px;
            "
            v-if="patentDetailData.hasInstructions"
          >
            <p class="patentDetail-title" style="font-weight: bold">说明书</p>
            <!-- <p>{{patentDetailData.instructions}}</p> -->
            <div
              v-for="(i, index) in patentDetailData.instructions"
              :key="`instructionSub${index}`"
              :ref="`instructionSub${index}`"
              :id="`instructionSub${index}`"
            >
              <p style="color: #2c6c8f">{{ i.sub_title }}</p>
              <p v-for="j in i.content" :key="j.line">
                {{ j.line_str }}{{ j.text }}
              </p>
            </div>
          </div>
          <!-- <div class="patentDetail-box" ref="instructionsPic">
            <p class="patentDetail-title">说明书附图</p>
            <p>{{patentDetailData.instructionsPic}}</p>
          </div> -->
        </div>
        <div
          class="patentDetail-right"
          style="width: 45%; height: 70vh; overflow-y: scroll"
        >
          <!-- <div class="patentDetail-right-item" ref="info">
            <p><b>摘要：</b></p>
            <p>{{patentDetailData.info}}</p>
          </div> -->
          <div
            class="patentDetail-right-item"
            ref="structure"
            style="
              margin-bottom: 15px;
              padding-bottom: 5px;
              border-bottom: 1px solid #e5e2e2;
            "
            v-show="structureData.structureNode.length != 0"
          >
            <p><b>专利发明结构：</b></p>
            <!-- <div class="structureTree" ref="tree"> -->
            <!-- </div> -->
            <StructureLink
              :structureData="structureData"
              v-show="structureData.structureNode.length != 0"
            ></StructureLink>
          </div>
          <div
            class="patentDetail-right-item"
            ref="tags"
            style="
              margin-bottom: 15px;
              padding-bottom: 5px;
              border-bottom: 1px solid #e5e2e2;
            "
            v-if="patentDetailData.tagList.length != 0"
          >
            <p><b>关键技术标签：</b></p>
            <el-tag
              class="tagBox-tag"
              style="margin-right: 5px; margin-top: 3px"
              size="small"
              type="warning"
              v-for="i in patentDetailData.tagList"
              :key="i"
              >{{ i }}</el-tag
            >
          </div>
          <div
            class="patentDetail-right-item"
            style="
              margin-bottom: 15px;
              padding-bottom: 5px;
              border-bottom: 1px solid #e5e2e2;
            "
            ref="content"
            v-if="
              patentDetailData.instructions_obj &&
              patentDetailData.instructions_obj != patentDetailData.effect
            "
          >
            <p><b>核心发明方案：</b></p>
            <p>{{ patentDetailData.instructions_obj }}</p>
          </div>
          <div
            class="patentDetail-right-item"
            style="
              margin-bottom: 15px;
              padding-bottom: 5px;
              border-bottom: 1px solid #e5e2e2;
            "
            ref="content"
            v-if="patentDetailData.effect"
          >
            <p><b>核心发明效果：</b></p>
            <p>{{ patentDetailData.effect }}</p>
          </div>
          <div
            class="patentDetail-right-item"
            style="
              margin-bottom: 15px;
              padding-bottom: 5px;
              border-bottom: 1px solid #e5e2e2;
            "
            ref="requirement"
            v-if="patentDetailData.main_claim"
          >
            <p><b>核心权力要求：</b></p>
            <p>{{ patentDetailData.main_claim }}</p>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import StructureLink from "@/components/StructureLink.vue";

export default {
  name: "PatentDetail",
  components: {
    StructureLink,
  },
  props: {
    // patentDetailVisible: Boolean,
    patentDetailData: Object,
    structureData: Object,
    showModal: Boolean,
  },
  watch: {
    visible: {
      handler(newVal, oldVal) {
        // console.log("newVal", newVal);
        if (newVal == false) {
          this.$emit("changeVisible", false);
        }
      },
      deep: true,
    },
    // patentDetailVisible: {
    //   handler(newVal, oldVal) {
    //     // console.log("newVal", newVal);
    //     this.visible = newVal;
    //   },
    //   deep: true,
    // },
    patentDetailData: {
      handler(newVal, oldVal) {
        if (!newVal.hasClaims) {
          this.sideList = [
            {
              name: "基本信息",
              ref: "baseInfo",
              index: "1",
            },
            {
              name: "摘要",
              ref: "info",
              index: "2",
            },
          ];
        } else {
          this.sideList = [
            {
              name: "基本信息",
              ref: "baseInfo",
              index: "1",
            },
            {
              name: "摘要",
              ref: "info",
              index: "2",
            },
            {
              name: "权利要求书",
              ref: "claims",
              index: "3",
            },
          ];
        }
        if (!newVal.hasInstructions) {
          this.sideList2 = [];
        } else {
          this.sideList2 = [
            {
              name: "说明书",
              ref: "instructions",
              index: "4",
              children: [],
            },
          ];
          this.sideList2[0].children = [];
          console.log("---patentDetailData", newVal);
          if (!newVal.instructions) return;
          newVal.instructions.forEach((i) => {
            this.sideList2[0].children.push(i.sub_title);
          });
        }
      },
      deep: true,
    },
  },
  directives: {
    drag: {
      // 指令的定义
      bind(el, binding, vnode, oldVnode) {
        const dialogHeaderEl = el.querySelector(".el-dialog__header");
        const dragDom = el.querySelector(".el-dialog");
        dialogHeaderEl.style.cursor = "move";

        // 获取原有属性 ie dom元素.currentStyle 火狐谷歌 window.getComputedStyle(dom元素, null);
        const sty =
          dragDom.currentStyle || window.getComputedStyle(dragDom, null);

        dialogHeaderEl.onmousedown = (e) => {
          // 鼠标按下，计算当前元素距离可视区的距离
          const disX = e.clientX - dialogHeaderEl.offsetLeft;
          const disY = e.clientY - dialogHeaderEl.offsetTop;

          // 获取到的值带px 正则匹配替换
          let styL, styT;

          // 注意在ie中 第一次获取到的值为组件自带50% 移动之后赋值为px
          if (sty.left.includes("%")) {
            styL =
              +document.body.clientWidth * (+sty.left.replace(/\%/g, "") / 100);
            styT =
              +document.body.clientHeight * (+sty.top.replace(/\%/g, "") / 100);
          } else {
            styL = +sty.left.replace(/\px/g, "");
            styT = +sty.top.replace(/\px/g, "");
          }

          document.onmousemove = function (e) {
            // 通过事件委托，计算移动的距离
            const l = e.clientX - disX;
            const t = e.clientY - disY;

            // 移动当前元素
            dragDom.style.left = `${l + styL}px`;
            dragDom.style.top = `${t + styT}px`;

            // 将此时的位置传出去
            // binding.value({x:e.pageX,y:e.pageY})
          };

          document.onmouseup = function (e) {
            document.onmousemove = null;
            document.onmouseup = null;
          };
        };
      },

      // bind: function (el) {
      //   let oDiv = el; // 获取当前元素
      //   oDiv.onmousedown = (e) => {
      //     // 算出鼠标相对元素的位置
      //     let disX = e.clientX - oDiv.offsetLeft;
      //     let disY = e.clientY - oDiv.offsetTop;

      //     document.onmousemove = (e) => {
      //       // 用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
      //       let left = e.clientX - disX;
      //       let top = e.clientY - disY;

      //       oDiv.style.left = left + "px";
      //       oDiv.style.top = top + "px";
      //     };

      //     document.onmouseup = (e) => {
      //       document.onmousemove = null;
      //       document.onmouseup = null;
      //     };
      //   };
      // },
      // inserted: function (e) {
      //   console.log("e.parentNode", e.parentNode)
      //   let el = e.parentNode.parentNode; // 获取当前元素
      //   e.onmousedown = (e) => {
      //     // 算出鼠标相对元素的位置
      //     let disX = e.clientX - el.offsetLeft;
      //     let disY = e.clientY - el.offsetTop;

      //     document.onmousemove = (e) => {
      //       e.preventDefault();
      //       // 用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
      //       let left = e.clientX - disX;
      //       let top = e.clientY - disY;

      //       el.style.left = left + "px";
      //       el.style.top = top + "px";
      //     };

      //     document.onmouseup = (e) => {
      //       document.onmousemove = null;
      //       document.onmouseup = null;
      //     };
      //   };
      // },
    },
  },
  data() {
    return {
      visible: true,
      sideList: [
        {
          name: "基本信息",
          ref: "baseInfo",
          index: "1",
        },
        {
          name: "摘要",
          ref: "info",
          index: "2",
        },
        {
          name: "权利要求书",
          ref: "claims",
          index: "3",
        },
        // {
        //   name: "说明书附图",
        //   ref: "instructionsPic",
        //   index: "5"
        // },
      ],
      sideList2: [
        {
          name: "说明书",
          ref: "instructions",
          index: "4",
          children: [],
        },
      ],
      instruction_list: [1, 2, 3, 4],
    };
  },
  methods: {
    handleSelect(val) {
      console.log("handleOpen", val);
    },
    // 渲染树形图
    setTree() {},
    jumpToWhere(ref) {
      let t = "";
      t = document.getElementById(ref).offsetTop;
      console.log("ttttt", t);

      // let t = document.getElementById(ref).offsetTop
      // let t = this.$refs[ref].scrollTop
      // let h = this.$refs[ref].offsetHeight
      // let ph = this.$refs.style.height
      // console.log("ph", ph)
      console.log("ref", ref);
      console.log("offsetTop", t);
      // console.log("offsetHeight", h)
      this.$refs.patentContent.scrollTo(0, t - 65);
      // document.getElementById(ref).scrollTo(0, t - 65);
      // console.log("滚动到", ph-t)
    },
    // 获取专利详情基本信息(mongo)
    getPatentDetailFromMongo(patent_id) {
      let params = {
        patent_id: patent_id,
      };
      api.getPatentDetailFromMongo(params).then((res) => {
        console.log("获取专利详情基本信息(mongo)", res);
        this.patentDetailData.name = res.data.title;
        this.patentDetailData.num = res.data.num;
        this.patentDetailData.application_date = res.data.application_date; // X.X 修复点击专利详情，时间变成公开日的bug
        this.patentDetailData.public_date = res.data.public_date;
        // this.patentDetailData.public_date = res.data.application_date; // X.X 修复点击专利详情，时间变成公开日的bug
        this.patentDetailData.status = res.data.status;
        this.patentDetailData.patenter = res.data.patenter
          ? res.data.patenter.join("、")
          : "";
        this.patentDetailData.patenter_now = res.data.patenter_now
          ? res.data.patenter_now.join("、")
          : "";
        this.patentDetailData.inventor = res.data.inventor
          ? res.data.inventor.join("、")
          : "";
        this.patentDetailData.designer = res.data.designer
          ? res.data.designer.join("、")
          : "";
        this.patentDetailData.ipc = res.data.ipc ? res.data.ipc.join("、") : "";
        this.patentDetailData.cpc = res.data.cpc ? res.data.cpc.join("、") : "";
        this.patentDetailData.info = res.data.abstract;
        this.patentDetailData.claims = res.data.claims;
        this.patentDetailData.instructions = res.data.instructions;
        this.patentDetailData.effect = res.data.effect;
        this.patentDetailData.patent_type = res.data.patent_type;
        this.patentDetailData.main_claim = res.data.main_claim;
        this.patentDetailData.instructions_obj = res.data.instructions_obj;
        console.log(
          "JSON.parse(JSON.stringify(res.data.instructions))",
          JSON.parse(JSON.stringify(res.data.instructions))
        );
        if (JSON.parse(JSON.stringify(res.data.instructions)) == []) {
          this.patentDetailData.hasInstructions = false;
        } else {
          if (res.data.instructions[0].content[0].line == "") {
            this.patentDetailData.hasInstructions = false;
          } else {
            this.patentDetailData.hasInstructions = true;
          }
        }
        if (res.data.claims[0].line == "") {
          this.patentDetailData.hasClaims = false;
        } else {
          this.patentDetailData.hasClaims = true;
        }
      });
    },
  },
  mounted() {
    // 获取专利数据
    getPatentDetailFromMongo()
    // if (!this.patentDetailData.hasInstructions) {
    //   this.sideList2 = []
    // } else {
    //   this.sideList2 = [
    //     {
    //       name: "说明书",
    //       ref: "instructions",
    //       index: "4",
    //       children: []
    //     },
    //   ]
    // }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.patentDetailTotal {
  .tagBox {
    &-tag {
      margin-right: 5px;
    }
  }
  .el-dialog__header {
    padding: 20px 20px 10px !important;
  }
  .el-dialog__wrapper {
    z-index: 2200;
  }
  .patentDetail {
    display: flex;
    width: 100%;
    &-left,
    &-right {
      width: 45%;
      height: 70vh;
      overflow-y: scroll;
      p {
        margin-bottom: 8px;
      }
      &-item {
        margin-bottom: 15px;
        padding-bottom: 5px;
        border-bottom: 1px solid #e5e2e2;
      }
    }
    &-left {
      width: 40%;
      margin-left: 2%;
      padding-right: 2%;
      margin-right: 2%;
    }
    &-title {
      font-weight: bold;
    }
    &-box {
      border-bottom: 1px solid rgb(229, 226, 226);
      margin-bottom: 10px;
    }
  }
}
</style>
