<template>
  <div class="patentCard-patent">
    <div style="width: 98%">
      <div class="patentCard-patent-name">
        <!-- <span style="color: #3e43f4">▍</span> -->
        <span class="company" style="margin-left: 5px; margin-right: 10px">{{
          patent.company
        }}</span>
        <span v-if="hasKeyword" style="cursor: pointer; font-weight: bold; color: #333333"
          @click="showPatentDetail(patent)" v-html="highlight(patent.name_faiss, hasKeyword)"></span>
        <span v-if="!hasKeyword" style="cursor: pointer; font-weight: bold; color: #333333"
          @click="showPatentDetail(patent)" v-html="esHL(patent.name)"></span>
        <span class="applicationDate" style="margin-left: 15px; font-size: 14px">{{ patent.application_date }}</span>
        <span style="margin-left: 15px; font-size: 14px">{{
          patent.inventor
        }}</span>
        <!-- <i class="el-icon-star-on" style="margin-left: 15px" v-if="patent.has_structure==true"></i> -->
        <span style="margin-left: 5px" v-if="patent.has_structure == true">*</span>
        <img src="@/assets/images/suanfa.png" style="width: 15px; height: 15px; margin-left: 10px" v-if="env != 'pro' &&
          (patent.extract_type == 2 || patent.extract_type == 3)
          " />
        <img src="@/assets/images/guize.png" style="width: 15px; height: 15px; margin-left: 10px" v-if="env != 'pro' &&
          (patent.extract_type == 1 || patent.extract_type == 3)
          " />
        <div class="patentCard-starAndMark">
          <el-select class="searchDetail-select" style="width: auto" size="mini" v-model="patent.mark" placeholder="请标注"
            @change="handleSelect(patent)" v-if="markable">
            <el-option v-for="item in markOptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <Star :apiData="patent" :type="'patent'" :isFavorites="isFavorites" @cancelStar="handleCancelStar"></Star>
        </div>
        <span style="
            display: flex;
            float: right;
            margin-top: 5px;
            margin-left: 10px;
          " v-if="showRadio" @cancelStar="handleCancelStar">
          <el-radio v-model="patent.industry_is_right" :label="1"
            @click.native.stop.prevent="changePatentRight(patent, 1)">正确</el-radio>
          <el-radio v-model="patent.industry_is_right" :label="0"
            @click.native.stop.prevent="changePatentRight(patent, 0)">错误</el-radio>
        </span>
      </div>
      <div style="margin-left: 4px; display: flex; justify-content: space-between;">
        <div>
          <el-tag size="small" type="warning" v-for="t in patent.tags" :key="t"
            style="margin-right: 8px; margin-bottom: 5px">{{ t }}</el-tag>
        </div>
        <p v-if="patent.patent_score" style="width: 120px;color: gray;font-size: 13px">评分: {{ patent.patent_score }}</p>
      </div>
      <div style="margin-left: 4px" v-if="env != 'pro' && patent.score">
        <el-tag size="small" type="primary" effect="dark" style="margin-right: 8px; margin-bottom: 5px">评分：{{ patent.score
        }}</el-tag>
      </div>
      <div class="infoAndContent">
        <div class="info" style="width: 98%">
          <ol class="info-content">
            <li style="font-size: 14px; margin-left: 5px">{{ patent.info }}</li>
          </ol>
        </div>
      </div>
    </div>
    <!-- <div class="imgBox" @click="patentStructureVisible=true" v-if="patent.has_graph.structure_node.length != 0">
      <StructureTreeMini :apiData="patent.has_graph"></StructureTreeMini>
    </div> -->
    <PatentDetail v-if="patentDetailVisible" :patentDetailData="patentDetailData" @changeVisible="handlePatentVisible"
      :structureData="structureData" :showModal="showModal"></PatentDetail>
    <PatentStructure :patentStructureVisible="patentStructureVisible" @changeVisible="handleStructureVisible">
    </PatentStructure>
  </div>
</template>

<script>
import api from "@/api/api";
import PatentDetail from "@/components/PatentDetail.vue";
import PatentStructure from "../components/PatentStructure.vue";
import StructureTreeMini from "../components/StructureTreeMini.vue";
import Star from "@/components/Star.vue";
import { EventBus } from "../utils/eventBus";

export default {
  name: "PatentCard",
  components: {
    PatentDetail,
    PatentStructure,
    StructureTreeMini,
    Star,
  },
  props: {
    patent: Object, // 专利内容
    showModal: Boolean, // 是否展示遮罩层
    showRadio: Boolean,
    isFavorites: Boolean,
    hasKeyword: String,
    markable: Boolean, // 是否可标注
    company_id: Number, // 该新闻列表对应的公司id（仅在产业搜索详情有用）
    searchValue: String, // 搜索内容（仅在产业搜索详情有用）
    searchType: Number,
  },
  // watch: {
  //   patent (newVal, oldVal) {
  //     if (newVal != null) {
  //       this.decodeImgLinks();
  //     }
  //   }
  // },
  data() {
    return {
      markOptions: [
        // 标记选项
        {
          label: "错误",
          value: 0,
        },
        {
          label: "尚可",
          value: 1,
        },
        {
          label: "良好",
          value: 2,
        },
        {
          label: "完美",
          value: 3,
        },
      ],
      env: "",
      structureData: {
        structureNode: [],
        structureLink: [],
      },
      patentDetailVisible: false,
      patentStructureVisible: false,
      patentDetailData: {
        name: "",
        num: "",
        application_date: "",
        public_date: "",
        status: "",
        patenter: "",
        patenter_now: "",
        inventor: "",
        designer: "",
        ipc: "",
        cpc: "",
        info: "",
        claims: "",
        instructions: "",
        instructionsPic: "",
        tagList: [],
        content: "",
        effect: "",
        requirement: "",
        structure: "",
        effect: "",
        patent_type: "",
      },
      sideList: [
        {
          name: "摘要",
          ref: "info",
          index: "1",
        },
        {
          name: "技术标签",
          ref: "tags",
          index: "2",
        },
        {
          name: "核心发明内容",
          ref: "content",
          index: "3",
        },
        {
          name: "核心权力要求",
          ref: "requirement",
          index: "4",
        },
        {
          name: "专利发明结构",
          ref: "structure",
          index: "5",
        },
      ],
    };
  },
  created() {
    // console.log(this.patent);
    // this.decodeImgLinks();
    console.log("当前环境", process.env.VUE_APP_CURRENTMODE);
    this.env = process.env.VUE_APP_CURRENTMODE;
  },
  methods: {
    // 标注
    handleSelect(val) {
      console.log(val)
      let params = {
        keyword: this.searchValue,
        company_id: this.company_id,
        grade: val.mark,
        search_type: this.searchType,
        score: val.score,
        data_type: "patent_name",
        data_id: val.id,
        value: val.name_faiss
      }
      console.log("params", params)
      api.changeCompanySearchMark(params).then(res => {
        console.log("res", res)
        if (res.data == 1) {
          this.$message.success("修改标注成功！")
        } else {
          this.$message.error("修改标注失败！")
        }
      }).catch(err => {
        this.$message.error("修改标注失败！")
        console.error("标注失败", err)
      })
    },
    handleCancelStar(val) {
      this.$emit("cancelStar", val);
    },
    // 修改es的highlight默认样式
    esHL(text) {
      text = text.replace(/<em>/g, '<span style="color:red;">');
      text = text.replace(/<\/em>/g, "</span>");
      // console.log("text", text)
      return text;
    },
    // 关键词红色高亮
    highlight(text, keyword, font_size) {
      if (text.indexOf(keyword) != -1) {
        text = text.replace(
          eval(`/${keyword}/g`),
          `<span style="color:red;font-size:${font_size};">${keyword}</span>`
        );
        // console.log("text", text)
        return text;
      } else {
        return text;
      }
    },
    // 修改专利产品判断
    changePatentRight(i, val) {
      let params = {
        patent_id: i.id,
        industry_is_right: val,
      };
      i.industry_is_right = val;
      console.log("修改专利产品判断val", i);
      this.$confirm("您确定要修改该专利的产品判断吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          api
            .changePatentRight(params)
            .then((res) => {
              if (res.status == 200) {
                this.$message.success("修改成功");
                // EventBus.$emit("change_polarity", 1)
              } else {
                this.$message({
                  message: res.msg,
                  type: "warning",
                });
              }
            })
            .catch((err) => {
              this.$message({
                message: "接口出错，请重试或联系管理员",
                type: "error",
              });
            });
        })
        .catch(() => {
          i.industry_is_right = null;
          this.$message({
            type: "info",
            message: "已取消修改",
          });
        });
    },
    // 获取专利详情基本信息(mongo)
    getPatentDetailFromMongo(patent_id) {
      let params = {
        patent_id: patent_id,
      };
      api.getPatentDetailFromMongo(params).then((res) => {
        console.log("获取专利详情基本信息(mongo)", res);
        this.patentDetailData.name = res.data.title;
        this.patentDetailData.num = res.data.num;
        this.patentDetailData.application_date = res.data.application_date;
        this.patentDetailData.public_date = res.data.public_date;
        this.patentDetailData.status = res.data.status;
        this.patentDetailData.patenter = res.data.patenter
          ? res.data.patenter.join("、")
          : "";
        this.patentDetailData.patenter_now = res.data.patenter_now
          ? res.data.patenter_now.join("、")
          : "";
        this.patentDetailData.inventor = res.data.inventor
          ? res.data.inventor.join("、")
          : "";
        this.patentDetailData.designer = res.data.designer
          ? res.data.designer.join("、")
          : "";
        this.patentDetailData.ipc = res.data.ipc ? res.data.ipc.join("、") : "";
        this.patentDetailData.cpc = res.data.cpc ? res.data.cpc.join("、") : "";
        this.patentDetailData.info = res.data.abstract;
        this.patentDetailData.claims = res.data.claims;
        this.patentDetailData.instructions = res.data.instructions;
        this.patentDetailData.effect = res.data.effect;
        this.patentDetailData.patent_type = res.data.patent_type;
        this.patentDetailData.main_claim = res.data.main_claim;
        this.patentDetailData.instructions_obj = res.data.instructions_obj;
        console.log(
          "JSON.parse(JSON.stringify(res.data.instructions))",
          JSON.parse(JSON.stringify(res.data.instructions))
        );
        if (JSON.parse(JSON.stringify(res.data.instructions)) == []) {
          this.patentDetailData.hasInstructions = false;
        } else {
          if (res.data.instructions[0].content[0].line == "") {
            this.patentDetailData.hasInstructions = false;
          } else {
            this.patentDetailData.hasInstructions = true;
          }
        }
        if (res.data.claims[0].line == "") {
          this.patentDetailData.hasClaims = false;
        } else {
          this.patentDetailData.hasClaims = true;
        }
      });
    },
    // 获取专利详情基本信息
    getPatentDetail(patent_id) {
      let params = {
        patent_id: patent_id,
      };
      api.getPatentDetail(params).then((res) => {
        console.log("专利详情", res);
        // this.patentDetailData = res.data
        this.patentDetailData.name = res.data.name;
        this.patentDetailData.num = res.data.num;
        this.patentDetailData.application_date = res.data.application_date;
        this.patentDetailData.public_date = res.data.public_date;
        this.patentDetailData.status = res.data.status;
        this.patentDetailData.patenter = res.data.patenter;
        this.patentDetailData.patenter_now = res.data.patenter_now;
        this.patentDetailData.inventor = res.data.inventor;
        this.patentDetailData.designer = res.data.designer;
        this.patentDetailData.ipc = res.data.ipc;
        this.patentDetailData.cpc = res.data.cpc;
        this.patentDetailData.info = res.data.info;
        this.patentDetailData.claims = res.data.claims;
        this.patentDetailData.instructions = res.data.instructions;
        this.patentDetailData.patent_type = res.data.patent_type;

        // this.patentDetailData.tagList = ["xxx", "yyy"]
      });
    },
    // 获取专利结构
    getPatentStructure(patent_id) {
      let params = {
        patent_id: patent_id,
      };
      api.getPatentStructure(params).then((res) => {
        console.log("获取专利结构", res);
        // this.structureData.structureNode = res.data.structure_node
        this.structureData.structureNode =
          res.data.focus_node.length != 0
            ? res.data.focus_node
            : res.data.structure_node;
        this.structureData.structureLink = res.data.structure_link;
      });
    },
    handlePatentVisible(val) {
      this.patentDetailVisible = val;
    },
    handleStructureVisible(val) {
      this.patentStructureVisible = val;
    },
    jumpToWhere(ref) {
      let t = this.$refs[ref].offsetTop;
      let h = this.$refs[ref].offsetHeight;
      this.$refs.patentContent.scrollTo(0, t - h / 2);
    },
    // 查看专利详情
    async showPatentDetail(patent) {
      // this.getPatentDetail(patent.id)
      this.getPatentDetailFromMongo(patent.id);
      this.getPatentStructure(patent.id);
      this.patentDetailData.tagList = patent.tags;
      // this.patentDetailData.tagList = patent.tags
      this.patentDetailVisible = true;
      // EventBus.$emit("structureData", this.structureData)
      // this.structureData.structureLink.push({ from_id: 133, to_id: 143, edge_name: "抵接", weight: 1 })
      // console.log("查看专利详情")
    },
    // 查看pdf
    handleCheckPdf(url) {
      window.open(url);
    },
    // 获取某元素在数组中的下标
    getArrIndex(arr, val) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] == val) return i;
      }
      return -1;
    },
    // 删除数组的某元素
    arrRemove(arr, val) {
      let index = this.getArrIndex(arr, val);
      if (index > -1) {
        arr.splice(index, 1);
      }
    },
    // 点击删除标签
    handleClose(value, type, patent) {
      console.log(value);
      console.log(type);
      console.log(patent);
      let params = {
        tag_type: type,
        tag_value: value,
        patent_id: patent.id,
      };
      this.$confirm("确定删除该标签吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          api
            .changeIndustryPatentTags(params)
            .then((res) => {
              if (res.status == 200) {
                this.arrRemove(patent[type], value);
                this.$message.success(res.msg);
              } else {
                this.$message.error(res.msg);
              }
            })
            .catch((err) => {
              this.$message.error("删除失败，请重试！");
            });
        })
        .catch((err) => {
          console.error(err);
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
ol li {
  list-style: none;
}

.patentCard {
  &-title {
    background-color: #01a4d9;
    display: inline-block;
    padding: 2px 10px 2px 10px;
    color: white;
    border-radius: 6px;
    margin-bottom: 5px;
  }

  &-rival {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }

  &-starAndMark {
    position: absolute;
    right: 8px;
    top: 6px;
    display: flex;
    align-items: center;
  }

  &-titleBox {
    display: flex;
    margin-bottom: 10px;
  }

  &-title2,
  &-title3 {
    // background-color: #01a4d9;
    border: 1px #01a4d9 solid;
    color: #01a4d9;
    padding: 2px 10px 2px 10px;
    cursor: pointer;
  }

  &-title2 {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  &-title3 {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  &-titleSelected {
    background-color: #01a4d9;
    color: white;
  }

  &-productSetting {
    width: 49%;
    border-right: 1px dashed gray;
    padding: 10px;
  }

  &-btn {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }

  &-patent {
    // width: 97%;
    // min-height: 30vh;
    position: relative;
    border-radius: 6px;
    background-color: #e2eefd;
    margin-top: 5px;
    padding: 10px 10px 10px 10px;
    display: flex;

    &-name {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 5px;
    }

    .patentDetail {
      display: flex;
      width: 100%;

      &-left,
      &-right {
        width: 55%;
        height: 70vh;
        overflow-y: scroll;

        p {
          margin-bottom: 8px;
        }

        &-item {
          margin-bottom: 15px;
        }
      }

      &-left {
        width: 35%;
        margin-left: 2%;
      }
    }
  }

  &-techLabel {
    width: 49%;
    // min-height: 30vh;
    border-radius: 6px;
    // background-color: #e2eefd;
    // margin-top: 10px;
    padding: 10px 10px 10px 10px;

    &-name {
      margin-bottom: 5px;
    }
  }

  &-metaBox {
    display: flex;
    position: relative;
  }

  .productType,
  .company {
    display: inline-block;
    margin-left: 20px;
    margin-bottom: 6px;
    background-color: orange;
    color: white;
    padding: 3px 6px 3px 6px;
    border-radius: 3px;
    font-size: 14px;
  }

  .company {
    background-color: #3e43f4;
  }

  .infoAndContent {
    display: flex;
    justify-content: space-between;
  }

  .info {
    margin-top: 10px;
    width: 49%;
    font-size: 15px;

    // margin-left: 1%;
    &-title {
      color: #333333;
      font-weight: bold;
    }

    &-content {
      overflow-y: scroll;
      height: 98px;
    }
  }

  .applicationDate {
    margin-left: 20px;
    font-size: 14px;
    font-weight: bold;
    color: #333333;
  }

  .newsList {
    display: flex;
    min-height: 27vh !important;
    position: relative;
  }

  .noflex {
    display: block;
    padding: 5px 10px 10px;
  }

  .treeBtn {
    margin-left: 10px;
  }

  .tree {
    font-size: 14px;
  }
}

.link {
  &-img {
    color: blue;
    cursor: pointer;
  }
}

.details {
  margin-top: 10px;
  font-size: 15px;
}

.productType,
.company {
  display: inline-block;
  margin-left: 8px;
  margin-bottom: 6px;
  background-color: orange;
  color: white;
  padding: 3px 6px 3px 6px;
  border-radius: 3px;
  font-size: 14px;
}

.company {
  margin-left: 20px;
  background-color: #3e43f4;
}

.infoAndContent {
  display: flex;
  justify-content: space-between;
}

.imgBox {
  display: flex;
  flex-direction: row-reverse;
  width: 25%;
  height: 160px;
  border-radius: 6px;
  background-color: white;
}

.img {
  &-tree {
    // width: 336.7px;
    // height: 160px;
    cursor: pointer;
    background-color: white;
  }
}
</style>
