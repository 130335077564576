<template>
  <div class="patentStructure">
    <el-dialog title="专利发明结构图" :visible.sync="visible" width="60vw">
      <StructureTree></StructureTree>
    </el-dialog>
  </div>
</template>

<script>
import StructureTree from "@/components/StructureTree.vue"

export default {
  name: "PatentStructure",
  components: {
    StructureTree
  },
  props: {
    patentStructureVisible: Boolean,
    patentStructureData: Object,
  },
  watch: {
    visible: {
      handler(newVal, oldVal) {
        // console.log("newVal", newVal);
        if (newVal == false) {
          this.$emit("changeVisible", false)
        }
      },
      deep: true,
    },
    patentStructureVisible: {
      handler(newVal, oldVal) {
        // console.log("newVal", newVal);
        this.visible = newVal
      },
      deep: true,
    },
  },
  data() {
    return {
      visible: false,
      sideList: [
        {
          name: "基本信息",
          ref: "baseInfo",
          index: "1"
        },
        {
          name: "摘要",
          ref: "info",
          index: "2"
        },
        {
          name: "权利要求书",
          ref: "claims",
          index: "3"
        },
        {
          name: "说明书",
          ref: "instructions",
          index: "4"
        },
        // {
        //   name: "说明书附图",
        //   ref: "instructionsPic",
        //   index: "5"
        // },
      ]
    };
  },
  methods: {
    // 渲染树形图
    setTree() {
      
    },
    jumpToWhere(ref) {
      let t = this.$refs[ref].offsetTop
      // let t = this.$refs[ref].scrollTop
      // let h = this.$refs[ref].offsetHeight
      // let ph = this.$refs.style.height
      // console.log("ph", ph)
      console.log("offsetTop", t)
      // console.log("offsetHeight", h)
      this.$refs.patentContent.scrollTo(0, t-65)
      // console.log("滚动到", ph-t)
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.patentStructure {
  .patentDetail {
      display: flex;
      width: 100%;
      &-left, &-right {
        width: 45%;
        height: 70vh;
        overflow-y: scroll;
        p {
          margin-bottom: 8px;
        }
        &-item {
          margin-bottom: 15px;
          padding-bottom: 5px;
          border-bottom: 1px solid #e5e2e2;
        }
      }
      &-left {
        width: 40%;
        margin-left: 2%;
        padding-right: 2%;
        margin-right: 2%;
      }
      &-title {
        font-weight: bold;
      }
      &-box {
        border-bottom: 1px solid rgb(229, 226, 226);
        margin-bottom: 10px;
      }
    }
}
</style>
