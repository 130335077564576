<template>
  <div class="structureTreeMini">
    <div class="treeContainer">
      <div class="treeChart" ref="treeChart"></div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "../utils/eventBus.js";
export default {
  name: "StructureTreeMini",
  props: {
    apiData: Object,
  },
  data() {
    return {
      treeChart: "",
      treeData: {
        name: "牵引车",
        children: [
          {
            name: "第一弹簧",
            children: [
              {
                name: "cluster",
                children: [
                  { name: "AgglomerativeCluster", value: 3938 },
                  { name: "CommunityStructure", value: 3812 },
                  { name: "HierarchicalCluster", value: 6714 },
                  { name: "MergeEdge", value: 743 },
                ],
              },
              {
                name: "xxx",
                children: [
                  { name: "BetweennessCentrality", value: 3534 },
                  { name: "LinkDistance", value: 5731 },
                  { name: "MaxFlowMinCut", value: 7840 },
                  { name: "ShortestPaths", value: 5914 },
                  { name: "SpanningTree", value: 3416 },
                ],
              },
              {
                name: "",
                children: [{ name: "AspectRatioBanker", value: 7074 }],
              },
            ],
          },
          {
            name: "主车体",
            children: [
              { name: "Easing", value: 17010 },
              { name: "FunctionSequence", value: 5842 },
              {
                name: "interpolate",
                children: [
                  { name: "ArrayInterpolator", value: 1983 },
                  { name: "ColorInterpolator", value: 2047 },
                  { name: "DateInterpolator", value: 1375 },
                  { name: "Interpolator", value: 8746 },
                  { name: "MatrixInterpolator", value: 2202 },
                  { name: "NumberInterpolator", value: 1382 },
                  { name: "ObjectInterpolator", value: 1629 },
                  { name: "PointInterpolator", value: 1675 },
                  { name: "RectangleInterpolator", value: 2042 },
                ],
              },
              { name: "ISchedulable", value: 1041 },
              { name: "Parallel", value: 5176 },
              { name: "Pause", value: 449 },
              { name: "Scheduler", value: 5593 },
              { name: "Sequence", value: 5534 },
              { name: "Transition", value: 9201 },
              { name: "Transitioner", value: 19975 },
              { name: "TransitionEvent", value: 1116 },
              { name: "Tween", value: 6006 },
            ],
          },
        ],
      },
    };
  },
  watch: {
    apiData: {
      async handler(newVal, oldVal) {
        console.log("structureTreeMini", newVal);
      },
      deep: true,
    },
  },
  methods: {
    // 监听图谱数据
    listenStructureData() {
      EventBus.$on("structureData", (data) => {
        console.log("structureData", data);
        this.graphData = [];
        this.graphLink = [];
        data.structureNode.forEach((i) => {
          this.graphData.push({
            id: i.node_id,
            name: i.node_name,
            category: i.level,
          });
        });
        data.structureTree.forEach((i) => {
          this.graphLink.push({
            source: i.from_id,
            target: i.to_id,
            value: i.edge,
          });
        });
        this.setOriginGraphChart();
        this.setGraphChart();
      });
    },
    formatData(data) {
      let id_name = {}
      let name_id = {}
      data.structure_node.forEach(i => {
        id_name[i.node_id] = i.node_name
        name_id[i.node_name] = i.node_id
        if (i.level == 0) {
          // console.log("formatData", i)
          this.treeData.name = i.node_name
          this.treeData.children = []
        }
      })
      data.structure_node.forEach(i => {
        
      })
      console.log("formatData", this.treeData)
    },
    // 对象数组根据属性去重
    unique(arr, attr) {
      const res = new Map();
      return arr.filter((a) => !res.has(a[attr]) && res.set(a[attr], 1));
    },
    // echart初始化
    setOriginTreeChart() {
      echarts.init(this.$refs.treeChart).dispose();
      this.treeChart = echarts.init(this.$refs.treeChart);
    },
    // 渲染echart
    setTreeChart() {
      this.treeData.children.forEach(function (datum, index) {
        index % 2 === 0 && (datum.collapsed = true);
      });
      let option = {
        tooltip: {
          trigger: "item",
          triggerOn: "mousemove",
        },
        series: [
          {
            type: "tree",
            data: [this.treeData],
            top: "1%",
            left: "17%",
            bottom: "1%",
            right: "25%",
            symbolSize: 8,
            label: {
              position: "left",
              verticalAlign: "middle",
              align: "right",
              fontSize: 8,
            },
            leaves: {
              label: {
                position: "right",
                verticalAlign: "middle",
                align: "left",
              },
            },
            emphasis: {
              focus: "descendant",
            },
            expandAndCollapse: true,
            animationDuration: 550,
            animationDurationUpdate: 750,
          },
        ],
      };
      this.treeChart.setOption(option)
    },
  },
  mounted() {
    console.log("structureTreeMini", this.apiData)
    this.formatData(this.apiData)
    this.setOriginTreeChart();
    this.setTreeChart();
  },
  // beforeDestroy() {
  //   EventBus.$off("structureData")
  // }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.structureTreeMini {
  width: 100%;
  height: 100%;
  .treeContainer {
    width: 100%;
    margin-left: 0%;
    height: 160px;
  }
  .treeChart {
    width: 100%;
    // width: 336.7px;
    // height: 30vw;
    height: 100%;
    margin-top: 5px;
  }
}
</style>
