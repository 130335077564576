<template>
    <div class="people">
        <div class="people-left">
            <div class="people-left-flexBox">
                <!-- <img src="@/assets/images/people.png" alt=""> -->
                <div class="compLogoTalen">
                    <p>{{talentsData.name | textCutPure(4)}}</p>
                </div>
                <p class="index">T-index: {{ talentsData.t_index }}</p>
            </div>
        </div>
        <div class="people-right">
            <div class="info">
                <div class="oneInfo">
                    <span class="name">{{ talentsData.name }}</span>
                    <div class="other" @click="handleShowPatent(talentsData.id)">
                        <img src="@/assets/images/patent.png" alt="">
                        <span class="patent">专利数：{{ talentsData.patent_total_num }}</span>
                    </div>
                    
                </div>
                <div class="towInfo">
                    <img src="@/assets/images/company.png" alt="">
                    <span v-for="(item,index) in talentsData.company_name_list"
                    :key="index">{{item}}</span>
                </div>
                <div class="threeInfo">
                    <img src="@/assets/images/label.png" alt="">
                    <span style="width:60px">技术标签:</span>
                    <span v-for="(item,index) in talentsDataTag" :key="index">{{ item }}</span>
                                      
                </div>
            </div>
        </div>
        <el-button size="mini" type="primary" style="position: absolute;right: 10px;top:10px;" @click="handleClickRiver">河流图</el-button>
        <el-dialog title="专利列表" :visible.sync="showPatent">
            <PatentCardList
            v-loading="patentLoading"
            :apiData="patent_list"
            :showModal="true"
            :total="patent_total"
            :page="patentParams.page"
            :page_size="patentParams.page_size"
            @changePage="handleChangePatentPage"
            :limit_page="10"
          ></PatentCardList>
        </el-dialog>
    </div>
</template>
<script>
import api from "@/api/api.js"
import PatentCardList from "@/components/PatentCardList.vue";
import cookie from "@/utils/common.js"

export default {
    props:{
        talentsData:Object,
    },
    components: {
        PatentCardList
    },
    data(){
        return{
            talentsDataTag: [],
            patentLoading: false,
            showPatent: false,
            patent_list: [],
            patent_total: 0,
            patentParams: {
                account: "",
                page: 1,
                page_size: 10,
                inventor_id: "",
            }
        }
    },
    mounted() {
        this.patentParams.account = cookie.getCookie("account");
    },
    methods:{
        // 响应加载更多专利
        handleChangePatentPage(val) {
        this.patentParams.page = val;
        this.getInventorPatent(this.talentsData.id);
        },
        // 展示专利列表
        handleShowPatent(inventor_id) {
            this.showPatent = true
            this.getInventorPatent(inventor_id)
        },
        // 获取人才专利
        getInventorPatent(inventor_id) {
            this.patentLoading = true
            this.patentParams.inventor_id = inventor_id
            api.getInventorPatent(this.patentParams).then(res => {
                console.log("getInventorPatent", res)
                this.patent_list = res.data
                this.patent_total = res.count
                this.patentLoading = false
            }).catch(err => {
                this.patentLoading = false
            })
        },
        // 点击河流图按钮
        handleClickRiver() {
            console.log("talentsData", this.talentsData)
            this.$emit("clickRiver", this.talentsData)
            // let params = {
            //     inventor_id: this.talentsData.id,
            //     count: 1000,
            //     years: 13
            // }
            // api.getInventorRiver(params).then(res => {
            //     console.log("Res", res)
            //     let river_tmp = JSON.parse(JSON.stringify(res.data))
            //     this.riverData = {}
            //     this.$nextTick(() => {
            //         this.riverData = river_tmp
            //         this.showRiver = true
            //     })
                
            // })
        },
        changeTagNum(num){
            this.talentsDataTag = this.talentsData.tech_tags
            // console.log('talentsData.tag:',this.talentsDataTag)
            let tagLength = this.talentsDataTag.length
            console.log('tl',tagLength)
            if (tagLength >num){
                for(let i = num;i< tagLength;i++){
                this.talentsDataTag.pop()
                }
                this.talentsDataTag.push('...')
            }
            // console.log('data.tag,',this.talentsDataTag)
        }
    },
    created(){      
        this.changeTagNum(10)
    }

}
</script>
<style lang="scss">
.people{
    width: 100%;
    height: 190px;
    // background: #000;
    display: flex;
    .el-dialog__body {
        padding-top: 0;
    }
    .people-left{
        flex: 1;
        // background-color: green;
        display: flex;
        justify-content: center;
        align-items: center;
        .people-left-flexBox{
            height: 120px;
            // background-color: blue;
            img{
                height: 90px;
                // background: red;
            }
            .compLogoTalen {
                // margin: 20px 10px 20px 25px;
                height: 90px;
                width: 90px;
                // background-color: white;
                // border: 1px solid gray;
                background-color: #01a4d9;
                border-radius: 6px;
                display: flex;
                justify-content: center;
                align-items: center;
                p {
                    width: 90px;
                    overflow: hidden;
                    word-break: break-all;
                    word-wrap: wrap;
                    margin-left: 7px;
                    text-align: center;
                    color: white;
                    font-size: 24px;
                    font-weight: bold;
                    letter-spacing: 8px;
                }
            }
            .index{
                margin-top: 10px;
                text-align: center;
                border: 1px solid #D7D7D7;
                border-radius: 5px;
                background: #fff;
            }
        }
    }
    .people-right{
        flex: 6;
        padding:40px 15px 0 10px;
        .info{
            height: 140px;
            display: flex;
            flex-direction:column;
            // justify-content: center;
            // align-items: center;
            span{
                background-color: #fff;    
            }
            .oneInfo{
                height: 30px;
                // flex: 1;
                display: flex;
                align-items: center;
                margin-top: 10px;
                .name{
                    // float: left;
                    font-family: 'Arial Negreta', 'Arial Normal', 'Arial';
                    font-size: 16px;
                    font-weight: 700;
                    font-style: normal;
                    margin-right: 15px;
                    color: #000000;
                    background-color: #fff;
                    border-color: transparent;
                }
                .other{
                    display: flex;
                    font-family: 'Arial Normal', 'Arial';
                    font-weight: 400;
                    font-size: 13px;
                    background: #fff;
                    border: 1px solid #D7D7D7;
                    padding-right: 5px;
                    border-radius: 5px;
                    img{
                        width: 20px;
                        height: 20px;
                    }
                    .patent{
                        
                    }
                }
            }
            .towInfo{
                // flex: 1;
                height: 30px;
                display: flex;
                align-items: flex-end;
                margin-top: 10px;
                // clear: both;
                img{
                    width: 20px;
                    height: 25px;
                    margin-right: 15px;
                }
            }
            .threeInfo{
                // flex: 1;
                height: 30px;
                display: flex;
                // align-items: flex-end;
                flex-wrap: wrap;
                justify-content:flex-start;
                align-items: flex-end;
                margin-top: 10px;
                img{
                    width: 20px;
                    height: 25px;
                    margin-right: 15px;
                }
                span{
                    margin-right: 5px;
                    margin-top: 5px;
                }
                
            }
        }
    }
}
</style>
